<template>
    <!-- eslint-disable max-len -->
    <div class="ConnectionError flex flex-col min-h-screen bg-day-01dp dark:bg-night-01dp">
        <TheDefaultHeader :help="true" />

        <main class="ConnectionError_Content flex-grow text-center flex flex-col justify-center px-5">
            <div class="relative mb-6">
                <div class="relative z-10">
                    <h1 class="mb-1">{{ $t('connection-error.heading') }}</h1>
                    <p class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis w-10/12 mx-auto">{{ $t('connection-error.description') }}</p>

                    <AppButton @click="$router.go(-1)" type="primary" class="mt-4 mx-auto">{{ $t('connection-error.button-back') }}</AppButton>
                </div>
            </div>
        </main>

        <TheBottomMenu />
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import AppButton from '@/components/Interface/AppButton.vue';

export default {
    name: 'ConnectionError',

    components: {
        TheDefaultHeader,
        TheBottomMenu,
        AppButton,
    },
};
</script>

<style lang="scss" scoped>
.ConnectionError {

    &_Content {
        margin-bottom: 84px; // Dock menu + toolbar

        &_Image {
            width: 7.5rem;
            height: 7.5rem;
        }

        &_Blur {
            opacity: 0.4;
            filter: blur(100px);
        }
    }
}
</style>
