<template>
    <!-- eslint-disable max-len -->
    <div class="NotFound flex flex-col min-h-screen bg-day-01dp dark:bg-night-01dp">
        <TheDefaultHeader :help="true" />

        <main class="NotFound_Content flex-grow text-center flex flex-col justify-center px-5">
            <div class="relative mb-6">
                <div class="relative z-10">
                    <!-- <img src="@/assets/images/not-found.gif" alt="Not Found" class="NotFound_Content_Image object-cover mx-auto rounded-full mb-4" /> -->

                    <h1 class="mb-1">{{ $t('not-found.heading') }}</h1>
                    <p class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis w-10/12 mx-auto">{{ $t('not-found.description') }}</p>

                    <AppButton @click="$router.back()" type="primary" icon="arrow-left" class="mt-4 mx-auto">{{ $t('not-found.button-back') }}</AppButton>
                </div>

                <!-- Background image blur -->
                <img src="@/assets/images/not-found.jpg" alt="Not Found" class="NotFound_Content_Blur absolute inset-0 w-auto h-full mx-auto rounded-full" />
            </div>
        </main>

        <TheBottomMenu />
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import AppButton from '@/components/Interface/AppButton.vue';

export default {
    name: 'NotFound',

    components: {
        TheDefaultHeader,
        TheBottomMenu,
        AppButton,
    },
};
</script>

<style lang="scss" scoped>
.NotFound {

    &_Content {
        margin-bottom: 84px; // Dock menu + toolbar

        &_Image {
            width: 7.5rem;
            height: 7.5rem;
        }

        &_Blur {
            opacity: 0.4;
            filter: blur(100px);
        }
    }
}
</style>
